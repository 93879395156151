<template>
  <div v-if="value" class="d-flex">
    <div>
      <b-badge class="icon-badge text-center" :variant="variant"><fa :icon="icon"></fa></b-badge>
    </div>
    <template v-if="type == 'address'">
      <a :href="addressMapsLink(text)" target="_blank">
        <wrapped-text :text="text" />
      </a>
    </template>
    <template v-else>
      <wrapped-text :text="text" />
    </template>
  </div>
</template>

<script lang="ts">
  import {Prop, Vue, Component} from "vue-property-decorator";
  import WrappedText from "@hc/ui/util/components/WrappedText.vue";
  @Component({
    components: {WrappedText}
  })
  export default class DecoratedProperty extends Vue {
    @Prop({required: true}) icon
    @Prop({required: true}) value
    @Prop({default: 'default'}) variant
    @Prop({default: 'text'}) type

    get text() {
      return String(this.value)
    }

    addressMapsLink(address){
      return `https://maps.google.com/?q=${encodeURIComponent(address.split("\n").join(', '))}`
    }
  }
</script>

<style lang="sass">
.rendered-markdown
  p
    margin-bottom: 0 !important
</style>